import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com"; // Import EmailJS
import "./job.css"; // Import CSS file for styling
import { Loader } from "./components/Loader";
import toast from "react-hot-toast";

const CourseApplication = () => {
  const [showPopup, setShowPopup] = useState(false); // State to show/hide popup
  const [errors, setErrors] = useState({}); // State for error messages
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const validateForm = (formData) => {
    const newErrors = {}; // Object to store error messages

    // Validate Full Name
    if (!formData.name) {
      newErrors.name = "Full Name is required.";
    }

    // Validate Email
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid.";
    }

    // Validate Mobile Number
    if (!formData.number) {
      newErrors.number = "Mobile Number is required.";
    } else if (!/^\d{10}$/.test(formData.number)) {
      newErrors.number = "Mobile Number must be 10 digits.";
    }

    // Validate Address
    if (!formData.address) {
      newErrors.address = "Address is required.";
    }

    // Validate Course
    if (!formData.course) {
      newErrors.course = "Course selection is required.";
    }

    return newErrors; // Return any errors found
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);
    // Collect form data
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      number: e.target.number.value,
      address: e.target.text.value,
      course: e.target.course.value,
    };

    const validationErrors = validateForm(formData); // Validate form data

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set errors if validation fails
      setLoading(false);
      return; // Stop submission if there are errors
    }

    sendEmail(formData); // Send email with form data if validation passes
  };

  const sendEmail = (formData) => {
    setLoading(false);
    toast.success("Form submitted successfully");
    // emailjs
    //   .send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", formData, "YOUR_USER_ID") // Replace with your EmailJS details
    //   .then(
    //     (result) => {
    //       console.log("Email successfully sent!", result.text);
    //       setShowPopup(true); // Show confirmation popup after submission
    //       setErrors({}); // Clear errors on successful submission
    //     },
    //     (error) => {
    //       console.log("Failed to send email. Error: ", error.text);
    //     }
    //   );
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup
    navigate(-1); // Redirect to the previous page
  };

  return (
    <div className="course-application">
      <h1>Internship Application Form</h1>
      {loading && <Loader />}
      <form onSubmit={handleSubmit}>
        {/* Full Name field */}
        <label>
          Full Name:
          <input type="text" name="name" required />
          {errors.name && <span className="error">{errors.name}</span>}{" "}
          {/* Show error */}
        </label>

        {/* Email field */}
        <label>
          Email:
          <input type="email" name="email" required />
          {errors.email && <span className="error">{errors.email}</span>}{" "}
          {/* Show error */}
        </label>

        {/* Mobile Number field */}
        <label>
          Mobile No:
          <input type="number" name="number" required />
          {errors.number && <span className="error">{errors.number}</span>}{" "}
          {/* Show error */}
        </label>

        {/* Address field */}
        <label>
          Address:
          <input type="text" name="text" required />
          {errors.address && (
            <span className="error">{errors.address}</span>
          )}{" "}
          {/* Show error */}
        </label>

        {/* Course selection */}
        <label>
          Course:
          <select name="course" required>
            <option value="">Select a course</option>
            <option value="frontend">Frontend Development</option>
            <option value="backend">Backend Development</option>
            <option value="fullstack">Full Stack Development</option>
            <option value="digital-marketing">Digital Marketing</option>
            <option value="ui-ux">UI/UX Design</option>
            <option value="app-development">App Development</option>
            <option value="paid-intern">Paid Intern</option>
          </select>
          {errors.course && <span className="error">{errors.course}</span>}{" "}
          {/* Show error */}
        </label>

        {/* Submit button */}
        <button type="submit">Submit</button>
      </form>

      {/* Popup for showing confirmation */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Application Submitted!</h2>
            <p>Your application has been submitted successfully.</p>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseApplication;
