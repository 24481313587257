import "./techCard.css";
import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

export const TechCard = (props) => {
  const { technologies } = props;
  const [value, setValue] = React.useState(technologies[0]?.name || "");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredTechnologies = technologies.find((tech) => tech.name === value);

  return (
    <div>
      <h2 className="tabHeading">Our Technolgoies</h2>
      <div className="tabCon">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {technologies.map((tech) => (
            <Tab
              key={tech.id}
              value={tech.name}
              label={tech.name.toLowerCase()} // Convert label to lowercase
              style={{
                textTransform: "Capitalize", // Prevent default capitalization
                justifyContent: "center", // Center the content
                display: "flex", // Use flexbox for centering
              }}
            />
          ))}
        </Tabs>
      </div>

      <div className="techCard">
        <div className="container">
          {filteredTechnologies?.list?.map((tech) => (
            <div key={tech.id} className="tech-item">
              <div className="icon">{tech.icon}</div>{" "}
              {/* Assuming tech.image is an icon */}
              <span className="name">{tech.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
