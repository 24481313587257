import "./animationCard.css";
import FrontendImage from "../images/technologies/web-design.png";
import BackendImage from "../images/technologies/backend.png";
import DatabaseImage from "../images/technologies/database.png";
import AppDevelopmentImage from "../images/technologies/app-development.png";
import ServerImage from "../images/technologies/server.png";
import UIUXImage from "../images/technologies/ui.png";

export const AnimationCard = () => {
  const technologies = [
    {
      id: "1",
      name: "Frontend Development",
      list: [
        "HTML,CSS",
        "JavaScript",
        "TypeScript",
        "React",
        "Next",
        "Vue.js",
        "Bootstrap",
        "Tailwind CSS ,SASS,MUI",
      ],
      image: FrontendImage,
    },
    {
      id: "2",
      name: "Backend Development",
      list: [
        "Node.js",
        "Express.js",
        "Django",
        "Flask",
        "Spring Boot",
        "FastAPI",
        "ASP.NET Core",
      ],
      image: BackendImage,
    },
    {
      id: "3",
      name: "App Development",
      list: ["React Native", "Flutter", "Swift", "Kotlin", "Java (Android)"],
      image: AppDevelopmentImage,
    },
    {
      id: "4",
      name: "Database Technologies",
      list: [
        "MySQL",
        "PostgreSQL",
        "MongoDB",
        "Firebase Firestore",
        "Solr",
        "Redis",
      ],
      image: DatabaseImage,
    },
    {
      id: "5",
      name: "DevOps & Cloud",
      list: ["Kubernetes", "Jenkins", "AWS", "Azure", "CI/CD"],
      image: ServerImage,
    },

    {
      id: "8",
      name: "UI/UX Design",
      list: ["Figma", "Adobe XD", "Sketch"],
      image: UIUXImage,
    },
  ];

  return (
    <div className="animationCard">
      {technologies.map((x) => (
        <div key={x.id} className="wrapper">
          <div className="card">
            <div className="front">
              <h1>{x.name}</h1>
              <p>{/* 7.7 deck<span>2018</span> */}</p>
            </div>
            <div className="right">
              <h2>Technolgoies</h2>

              <ul>
                {x.list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="img-wrapper">
            <img
              //   src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/577128/deck.png"
              className="animationImage"
              src={x.image}
              alt=""
            />
          </div>
        </div>
      ))}
    </div>
  );
};
