import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./App.css";

import logo from "./images/vslogo.png";

import "./card.css";

import lg from "./images/menu.gif";

export const Header = () => {
  const markerRef = useRef(null);
  const listRefs = useRef([]);
  const bannerRefs = useRef([]);
  const pageTransitionRef = useRef(null);
  const navRef = useRef(null);
  const logoRef = useRef(null);
  const headingRef = useRef(null);
  const location = useLocation(); // Hook to get current route

  const [currentIndex, setCurrentIndex] = useState(0);

  const cardsRef = useRef(null);
  const bannercontentRef = useRef(null);
  const navigate = useNavigate();
  const handleMenuToggle = () => {
    if (window.innerWidth <= 568) {
      // Check if the screen width is 768px or less
      navRef.current.classList.toggle("show");
    }
  };

  useEffect(() => {
    const marker = markerRef.current;
    const banners = bannerRefs.current;
    const list = listRefs.current;
    const pageTransition = pageTransitionRef.current;
    const nav = navRef.current;
    const logo = logoRef.current;
    const heading = headingRef.current;

    const bannercontentRef = document.querySelectorAll(".banner-content");

    gsap.registerPlugin(ScrollTrigger);

    // Move indicator animation
    function moveIndicator(e) {
      if (marker) {
        gsap.to(marker, {
          left: e.target.offsetLeft,
          width: e.target.offsetWidth,
          duration: 0.5,
        });
      }
    }

    function activeLink(e) {
      list.forEach((item) => {
        if (item) item.classList.remove("active");
      });
      e.target.closest("li").classList.add("active");
    }

    list.forEach((item) => {
      if (item) {
        item.addEventListener("mousemove", moveIndicator);
        item.addEventListener("mouseover", activeLink);
      }
    });

    // Banner animations

    // Page transition animation
    gsap.fromTo(
      pageTransition,
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, duration: 1.5, ease: "power2.out" }
    );

    // Logo rotation animation
    gsap.fromTo(
      logo,
      { rotate: 0 },
      { rotate: 360, duration: 2, ease: "power2.inOut" }
    );

    // Heading animations
    gsap.fromTo(
      heading,
      { opacity: 0, y: "100%" },
      { opacity: 1, y: "0%", duration: 1.5, delay: 1, ease: "power2.out" }
    );

    // Navigation animation
    gsap.fromTo(
      nav,
      { opacity: 0, x: "100%" },
      { opacity: 1, x: "0%", duration: 1.5, delay: 2, ease: "power2.out" }
    );

    return () => {
      list.forEach((item) => {
        if (item) {
          item.removeEventListener("mousemove", moveIndicator);
          item.removeEventListener("mouseover", activeLink);
        }
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.registerPlugin(ScrollTrigger);

    const bannercontentRef = document.querySelectorAll(".banner-content");
    // Animate banner content
    gsap.fromTo(
      ".banner-content h1",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 5, ease: "power2.out" }
    );

    gsap.fromTo(
      ".banner-content h2",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 3, delay: 5, ease: "power2.out" }
    );

    return () => {
      gsap.killTweensOf(".banner-content h1");
      gsap.killTweensOf(".banner-content h2");
    };
  }, []);

  const paths = [
    "/home",
    "/services",
    "/creation",
    "/about",
    "/contact",
    "/career",
  ];

  useEffect(() => {
    // Update active index based on current route

    const currentPathIndex = paths.indexOf(location.pathname);
    setCurrentIndex(currentPathIndex);
  }, [location]);

  return (
    <>
      <header className="App-header">
        <div className="header-left">
          <img src={logo} ref={logoRef} className="App-logo" alt="logo" />

          <div>
            <p style={{ color: "white", margin: 0 }}>
              <a
                href="tel:+919095422237"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <i
                  className="fa fa-phone"
                  style={{ height: "2rem", width: "2rem" }}
                ></i>
              </a>
              <a
                href="mailto:vsoftsolutions8813@gmail.com"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <i
                  className="fa fa-envelope"
                  style={{ height: "2rem", width: "2rem" }}
                ></i>
              </a>
            </p>
            <button className="menu-button" onClick={handleMenuToggle}>
              <img src={lg} alt="Menu" className="menu-gif" />
            </button>
          </div>
        </div>

        <nav className="App-nav" ref={navRef}>
          <ul>
            <li
              // ref={(el) => (listRefs.current[0] = el)}
              className={
                location.pathname.includes("/home") || location.pathname == "/"
                  ? "active"
                  : ""
              }
            >
              <Link to="/home">
                <ion-icon name="home-outline"></ion-icon>
                <h6 className="custom-heading1">Vsflows</h6>
              </Link>
            </li>
            <li
              // ref={(el) => (listRefs.current[1] = el)}
              className={
                location.pathname.includes("/services")
                  ? "active dropdown"
                  : "dropdown"
              }
            >
              <Link to="/services">
                <ion-icon name="add-circle-outline"></ion-icon>
                <h6 className="custom-heading2">Services</h6>
              </Link>
              <div className="dropdown-content">
                <Link to="/services/ws">Website Development</Link>
                <Link to="/services/appsdevelop">App Development</Link>
                <Link to="/services/digi">Digital Marketing</Link>
                <Link to="/services/seo">Seo Services</Link>
                <Link to="/services/ui">UI/UX Designs</Link>
              </div>
            </li>

            <li
              // ref={(el) => (listRefs.current[2] = el)}
              className={
                location.pathname.includes("/creation") ? "active " : ""
              }
            >
              <Link to="/creation">
                <ion-icon name="settings-outline"></ion-icon>
                <h6 className="custom-heading1">Creations</h6>
              </Link>
            </li>

            <li
              // ref={(el) => (listRefs.current[3] = el)}
              className={location.pathname.includes("/about") ? "active " : ""}
            >
              <Link to="/about">
                <ion-icon name="chatbubble-outline"></ion-icon>
                <h6 className="custom-heading2">About</h6>
              </Link>{" "}
            </li>

            <li
              // ref={(el) => (listRefs.current[4] = el)}
              className={
                location.pathname.includes("/contact") ? "active " : ""
              }
            >
              <Link to="/contact">
                <ion-icon name="person-outline"></ion-icon>
                <h6 className="custom-heading1">Contact</h6>
              </Link>
            </li>
            <li
              // ref={(el) => (listRefs.current[0] = el)}
              className={location.pathname.includes("/career") ? "active " : ""}
            >
              <Link to="/career">
                <ion-icon name="home-outline"></ion-icon>
                <h6 className="custom-heading1"> IT Training & Career</h6>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
