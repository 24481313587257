import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com"; // Import EmailJS
import "./job.css";
import { Loader } from "./components/Loader";
import toast from "react-hot-toast";

const CourseApplication = () => {
  const [showPopup, setShowPopup] = useState(false); // State to show/hide popup
  const [errors, setErrors] = useState({}); // State to track form validation errors
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validateForm = (formData) => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Full Name is required.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (formData.number.length < 10 || formData.number.length > 15) {
      newErrors.number = "Mobile number should be between 10 and 15 digits.";
    }

    if (!formData.text.trim()) {
      newErrors.text = "Address is required.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      number: e.target.number.value,
      text: e.target.text.value,
      course: e.target.course.value,
    };

    const formErrors = validateForm(formData);

    if (Object.keys(formErrors).length === 0) {
      // If no errors, proceed with form submission
      sendEmail(e); // Send email using EmailJS
      setShowPopup(true); // Show confirmation popup after submission
    } else {
      setErrors(formErrors); // Set errors to display them to the user
      setLoading(false);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      number: e.target.number.value,
      text: e.target.text.value,
      course: e.target.course.value,
    };

    setLoading(false);
    toast.success("Form submitted successfully");
    // emailjs
    //   .send(
    //     "YOUR_SERVICE_ID", // Replace with your EmailJS service ID
    //     "YOUR_TEMPLATE_ID", // Replace with your EmailJS template ID
    //     formData, // Send the formData object
    //     "YOUR_USER_ID" // Replace with your EmailJS user ID
    //   )
    //   .then(
    //     (result) => {
    //       console.log("Email successfully sent!", result.text);
    //       setShowPopup(true); // Show confirmation popup after successful email
    //       setLoading(false);
    //     },
    //     (error) => {
    //       console.error("Failed to send email. Error: ", error.text);
    //       alert(
    //         "There was an error sending your application. Please try again later."
    //       ); // Notify the user of the error
    //       setLoading(false);
    //     }
    //   );
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    navigate(-1); // Redirect to the previous page
  };

  return (
    <div className="course-application">
      <h1>Course Application Form</h1>

      {loading && <Loader />}
      <form onSubmit={handleSubmit}>
        {/* Full Name field */}
        <label>
          Full Name:
          <input type="text" name="name" />
          {errors.name && <span className="error">{errors.name}</span>}{" "}
          {/* Error display */}
        </label>

        {/* Email field */}
        <label>
          Email:
          <input type="email" name="email" />
          {errors.email && <span className="error">{errors.email}</span>}
        </label>

        {/* Mobile Number field */}
        <label>
          Mobile No:
          <input type="number" name="number" />
          {errors.number && <span className="error">{errors.number}</span>}
        </label>

        {/* Address field */}
        <label>
          Address:
          <input type="text" name="text" />
          {errors.text && <span className="error">{errors.text}</span>}
        </label>

        {/* Course selection */}
        <label>
          Course:
          <select name="course">
            <option value="frontend">Frontend Development</option>
            <option value="backend">Backend Development</option>
            <option value="fullstack">Full Stack Development</option>
            <option value="digital-marketing">Digital Marketing</option>
            <option value="ui-ux">UI/UX Design</option>
            <option value="app-development">App Development</option>
            <option value="paid-intern">Paid Intern</option>
          </select>
        </label>

        {/* Submit button */}
        <button type="submit">Submit</button>
      </form>

      {/* Popup for showing confirmation */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Application Submitted!</h2>
            <p>Your application has been submitted successfully.</p>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseApplication;
